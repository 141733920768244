import React from "react";
import Markdown from 'react-remarkable';
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from "../../components/Layout";
import GenericPageTemplate from "../../templates/generic-page";

import styles from "./index.module.scss";

const FAQPage = ({intl}) => {
  return (
    <Layout>
      <GenericPageTemplate heroTitle={intl.formatMessage({id: 'faq.title'})}>
        <div className={styles.faqWrapper}>
          <Markdown options={{html: true}}>{intl.formatMessage({id: 'faq.text'})}</Markdown>
        </div>
      </GenericPageTemplate>
    </Layout>
  );
};

export default injectIntl(FAQPage);
